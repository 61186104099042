import Css from "./style.module.scss";

import React from "react";
import classNames from "classnames";

const FormCol = ({ className, ...restProps }) => {
  return (
    <div className={classNames(Css.formCol, className)} {...restProps} />
  );
};

export default React.memo(FormCol);
