import Css from "./style.module.scss";

import * as Icons from "@phosphor-icons/react";
import { Preloader, Select } from "nlib/ui";
import { checkContactsFetching, getContactsData } from "selectors/contacts";
import { getTextsData } from "selectors/texts";
import { useSelector } from "react-redux";
import Button from "nlib/ui/Button";
import Constants from "const/Constants";
import ContactsTable from "./lib/ContactsTable";
import DebounceInput from "nlib/ui/DebounceInput";
import NoDataContent from "nlib/common/NoDataContent";
import Page from "nlib/common/Page";
import PageContent from "nlib/common/PageContent";
import PageHeader from "nlib/common/PageHeader";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import SelectVendorSubType from "./lib/SelectVendorSubType";
import Sidebar from "./lib/Sidebar";
import Utils from "utils/Utils";
import classNames from "classnames";
import useAvailableWidth from "hooks/useAvailableWidth";
import useEnvVars from "hooks/useEnvVars";

const NEW_ITEM_ID = "new";

const SIMPLIFIED_LAYOUT_MAX_WIDTH = 960;

const ContactsPage = () => {
  const [envVars, setEnvVars] = useEnvVars();

  const { uiTexts, countries } = useSelector(getTextsData);

  const contactsData = useSelector(getContactsData);

  const fetchingData = useSelector(checkContactsFetching);

  const { containerRef, availableWidth } = useAvailableWidth([envVars.editItem]);

  const [searchStr, setSearchStr] = useState("");

  const showResetFiltersButton = [envVars.text, envVars.subType, envVars.countryCode].some(Boolean);

  const simplifyLayout = availableWidth <= SIMPLIFIED_LAYOUT_MAX_WIDTH;

  const countryOptions = useMemo(() => {
    const options = [...new Set(contactsData.map((contactData) => contactData.countryCode))]
      .filter(Boolean).map((code) => ({ value: code, label: countries[code] }));

    return [
      ...options,
      { value: null, label: uiTexts.all }
    ];
  }, [contactsData, countries, uiTexts]);

  const filteredData = useMemo(() => {
    return contactsData.filter((item) => {
      if (envVars.subType && item.subType !== envVars.subType) return false;
      if (envVars.countryCode && item.countryCode !== envVars.countryCode) return false;
      if (envVars.text) {
        const search = envVars.text.trim().toLowerCase();

        const { id, businessId, type, subType, countryCode, ...restData } = item;

        const found = Object.values(restData).some((value) => {
          return value && `${value}`.toLowerCase().includes(search);
        });

        if (!found) return false;
      }

      return true;
    });
  }, [contactsData, envVars.countryCode, envVars.subType, envVars.text]);

  const sortedData = useMemo(() => {
    return Utils.arraySort(filteredData, (item) => {
      switch (envVars.sortBy) {
        case "address": {
          const { street, city, state, zipCode, countryCode } = item;

          return `${[street, city, state, zipCode, countryCode].filter(Boolean).join(" ")}, ${
            countries[countryCode] || countryCode || ""
          }`;
        }
        case "subType":
          return item[envVars.sortBy] || "z";
        default:
          return item[envVars.sortBy] || "";
      }
    }, envVars.sortOrder === "asc");
  }, [filteredData, envVars.sortOrder, envVars.sortBy, countries]);

  const page = envVars.page || 1;

  const pageSize = envVars.pageSize || Constants.TABLE_PAGE_SIZE;

  const offset = (page - 1) * pageSize;

  const limit = offset + pageSize;

  const tableData = sortedData.slice(offset, limit);

  const handleSearchChange = useCallback((value) => {
    setEnvVars({ text: value || null });
  }, [setEnvVars]);

  const handleSubTypeChange = useCallback((value) => {
    setEnvVars({ subType: value });
  }, [setEnvVars]);

  const handleCountryChange = useCallback((value) => {
    setEnvVars({ countryCode: value });
  }, [setEnvVars]);

  const handleResetFiltersClick = useCallback(() => {
    setEnvVars({
      text: null,
      subType: null,
      countryCode: null
    });
  }, [setEnvVars]);

  const handleAddContactButtonClick = useCallback(() => {
    setEnvVars({ editItem: NEW_ITEM_ID });
  }, [setEnvVars]);

  const handleSideBarClose = useCallback((contactId = null) => {
    setEnvVars({ editItem: contactId });
  }, [setEnvVars]);

  useEffect(() => {
    setSearchStr(envVars.text || "");
  }, [envVars.text]);

  useEffect(() => {
    if (fetchingData || !contactsData.length || tableData.length) return;
    setEnvVars({ page: Math.ceil(sortedData.length / pageSize) || null });
  }, [contactsData.length, fetchingData, page, pageSize, setEnvVars, sortedData.length, tableData.length]);

  useEffect(() => {
    if (!fetchingData && envVars.countryCode) {
      if (!Utils.arrayFind(countryOptions, "value", envVars.countryCode)) {
        setEnvVars({ countryCode: null });
      }
    }
  }, [fetchingData, countryOptions, envVars.countryCode, setEnvVars]);

  return (
    <>
      <Page className={Css.contactsPage} pageRef={containerRef} scrollTop={envVars.page}>
        <PageContent>
          <PageHeader className={Css.pageHeader}>
            {!!tableData.length && !showResetFiltersButton
          && (envVars.editItem !== NEW_ITEM_ID)
          && (
            <Button
              large primary
              disabled={fetchingData}
              icon={Icons.Plus}
              onClick={handleAddContactButtonClick}>
              <span>{uiTexts.addNewContact}</span>
            </Button>
          )}
          </PageHeader>
          <div className={classNames(Css.filters, showResetFiltersButton && Css.sticky)}>
            <DebounceInput
              cleanable
              active={!!envVars.text}
              iconBefore={Icons.MagnifyingGlass}
              placeholder={uiTexts.searchText}
              className={Css.input}
              value={searchStr}
              onChange={setSearchStr}
              onInputComplete={handleSearchChange} />
            <SelectVendorSubType
              active={!!envVars.subType}
              className={Css.select}
              value={envVars.subType}
              onChange={handleSubTypeChange} />
            <Select
              active={!!envVars.countryCode}
              className={Css.select}
              placeholder={uiTexts.selectCountry}
              value={envVars.countryCode}
              options={countryOptions}
              onChange={handleCountryChange} />
            {showResetFiltersButton && (
              <Button
                danger large outline
                icon={Icons.X}
                className={Css.button}
                onClick={handleResetFiltersClick}>
                {uiTexts.resetFilters}
              </Button>
            )}
          </div>
          {tableData.length
            ? (
              <ContactsTable
                tableData={tableData}
                filteredData={filteredData}
                fetchingData={fetchingData}
                simplifyLayout={simplifyLayout} />
            )
            : (
              <div className={Css.emptyState}>
                {fetchingData ? <Preloader /> : (
                  <NoDataContent
                    title={uiTexts.noContacts}>
                    {!showResetFiltersButton && (
                      <Button
                        large primary
                        disabled={fetchingData}
                        icon={Icons.Plus}
                        onClick={handleAddContactButtonClick}>
                        <span>{uiTexts.addNewContact}</span>
                      </Button>
                    )}
                  </NoDataContent>
                )}
              </div>
            )}
        </PageContent>
      </Page>
      {envVars.editItem && (
        <Sidebar
          key={envVars.editItem}
          itemId={envVars.editItem}
          onClose={handleSideBarClose} />
      )}
    </>
  );
};

export default React.memo(ContactsPage);
