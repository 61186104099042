import { INPUT_FIELD_NAMES } from "nlib/common/ContactForm/constants";
import { getTextsData } from "selectors/texts";
import { useSelector } from "react-redux";
import Input from "nlib/ui/Input";
import React from "react";
import useContactFormContext from "nlib/common/ContactForm/useContactFormContext";

const ContactFormIdInput = (props) => {
  const { uiTexts } = useSelector(getTextsData);

  const { value, disabled, readOnly, onChange } = useContactFormContext(INPUT_FIELD_NAMES.ID_NUMBER);

  return (
    <Input
      placeholder={uiTexts.enterBusinessId}
      {...props}
      disabled={disabled}
      readOnly={readOnly}
      value={value || ""}
      onChange={onChange} />
  );
};

export default React.memo(ContactFormIdInput);
