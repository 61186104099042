import { INPUT_FIELD_NAMES } from "nlib/common/ContactForm/constants";
import { getTextsData } from "selectors/texts";
import { useSelector } from "react-redux";
import ContactFormContext from "contexts/ContactFormContext";
import Input from "nlib/ui/Input";
import React, { useContext } from "react";
import useContactFormContext from "nlib/common/ContactForm/useContactFormContext";

const ContactFormTaxIdInput = (props) => {
  const { uiTexts } = useSelector(getTextsData);

  const { state } = useContext(ContactFormContext);

  const { value, disabled, readOnly, onChange } = useContactFormContext(INPUT_FIELD_NAMES.VAT_ID);

  return (
    <Input
      placeholder={uiTexts.enterVatId}
      {...props}
      disabled={disabled}
      readOnly={readOnly}
      value={value || ""}
      invalid={!!(value && state.vatPayer === false)}
      onChange={onChange} />
  );
};

export default React.memo(ContactFormTaxIdInput);
