import Css from "./style.module.scss";

import { checkContactsFetching, getContactsData } from "selectors/contacts";
import { getTextsData } from "selectors/texts";
import { useDispatch, useSelector } from "react-redux";
import Button from "nlib/ui/Button";
import ContactForm, {
  ContactFormCityInput,
  ContactFormEmailInput,
  ContactFormNameInput,
  ContactFormPhoneInput,
  ContactFormSelectCountry,
  ContactFormSelectState,
  ContactFormStreetInput,
  ContactFormSubTypeSelect,
  ContactFormZipCodeInput
} from "nlib/common/ContactForm";
import ContactFormIdInput from "nlib/common/ContactForm/lib/ContactFormIdInput";
import ContactFormTaxIdInput from "nlib/common/ContactForm/lib/ContactFormTaxIdInput";
import ContactsActions from "actions/ContactsActions";
import FormCol from "nlib/common/FormCol";
import FormLabel from "nlib/common/FormLabel";
import FormRow from "nlib/common/FormRow";
import Preloader from "nlib/common/Preloader";
import React, { useCallback, useState } from "react";
import SideBar, { SideBarContent, SideBarFooter, SideBarHeader } from "nlib/common/SideBar";
import Utils from "utils/Utils";
import useShowCommonModal from "hooks/useShowCommonModal";

const Sidebar = ({ itemId, onClose }) => {
  const dispatch = useDispatch();

  const { uiTexts, messages } = useSelector(getTextsData);

  const contactsData = useSelector(getContactsData);

  const fetchingData = useSelector(checkContactsFetching);

  const showCommonModal = useShowCommonModal();

  const newItem = itemId === "new";

  const [disabled, setDisabled] = useState(false);

  const [willClose, setWillClose] = useState(false);

  const initialLoading = !newItem && !Utils.arrayFindById(contactsData, itemId) && fetchingData;

  const handleFormSubmit = useCallback(async({ state }) => {
    if (newItem) {
      await dispatch(ContactsActions.addNewContact(state)).then((result) => {
        if (result?.id) onClose(result?.id);
      });
    } else {
      const { id: contactId, ...restContactData } = state;

      await dispatch(ContactsActions.editContact(contactId, restContactData));
      onClose();
    }
  }, [newItem, dispatch, onClose]);

  const handleCloseButtonClick = useCallback(() => {
    setWillClose(true);
  }, []);

  const handleFormClose = useCallback(async({ hasChanges, validState, onSubmit }) => {
    if (hasChanges && validState) {
      const result = await showCommonModal({
        text: messages.unsavedChangesWarning,
        headerText: uiTexts.warning,
        confirm: true,
        okButtonText: uiTexts.yes,
        cancelButtonText: uiTexts.no
      });

      if (result) await onSubmit();
    }
    onClose();
  }, [messages, uiTexts, showCommonModal, onClose]);

  return (
    <ContactForm
      willClose={willClose}
      wrapper={null}
      disabled={disabled}
      vendorId={newItem ? null : itemId}
      setDisabled={setDisabled}
      onSubmit={handleFormSubmit}
      onClose={handleFormClose}>
      {({ validState, hasChanges, onSubmit }) => {
        return (
          <SideBar className={Css.sidebar}>
            <SideBarHeader onCloseClick={handleCloseButtonClick}>
              {newItem ? uiTexts.addNewContact : uiTexts.editContact}
            </SideBarHeader>
            <SideBarContent>
              {fetchingData
                ? <Preloader />
                : (!initialLoading && (
                  <>
                    <FormRow>
                      <FormCol>
                        <FormLabel>{uiTexts.name}</FormLabel>
                        <ContactFormNameInput />
                      </FormCol>
                    </FormRow>
                    <FormRow>
                      <FormCol>
                        <FormLabel>{uiTexts.type}</FormLabel>
                        <ContactFormSubTypeSelect />
                      </FormCol>
                    </FormRow>
                    <FormRow>
                      <FormCol>
                        <FormLabel>{uiTexts.businessId}</FormLabel>
                        <ContactFormIdInput />
                      </FormCol>
                    </FormRow>
                    <FormRow>
                      <FormCol>
                        <FormLabel>{uiTexts.vatId}</FormLabel>
                        <ContactFormTaxIdInput />
                      </FormCol>
                    </FormRow>
                    <FormRow>
                      <FormCol>
                        <FormLabel>{uiTexts.phone}</FormLabel>
                        <ContactFormPhoneInput />
                      </FormCol>
                    </FormRow>
                    <FormRow>
                      <FormCol>
                        <FormLabel>{uiTexts.email}</FormLabel>
                        <ContactFormEmailInput />
                      </FormCol>
                    </FormRow>
                    <FormRow>
                      <FormCol>
                        <FormLabel>{uiTexts.country}</FormLabel>
                        <ContactFormSelectCountry portal />
                      </FormCol>
                    </FormRow>
                    <FormRow>
                      <FormCol>
                        <FormLabel>{uiTexts.state}</FormLabel>
                        <ContactFormSelectState />
                      </FormCol>
                    </FormRow>
                    <FormRow>
                      <FormCol>
                        <FormLabel>{uiTexts.city}</FormLabel>
                        <ContactFormCityInput />
                      </FormCol>
                    </FormRow>
                    <FormRow>
                      <FormCol>
                        <FormLabel>{uiTexts.street}</FormLabel>
                        <ContactFormStreetInput />
                      </FormCol>
                    </FormRow>
                    <FormRow>
                      <FormCol>
                        <FormLabel>{uiTexts.zipCode}</FormLabel>
                        <ContactFormZipCodeInput />
                      </FormCol>
                    </FormRow>
                  </>
                ))}
            </SideBarContent>
            <SideBarFooter>
              <Button
                large outline
                onClick={handleCloseButtonClick}>
                {uiTexts.cancel}
              </Button>
              <Button
                large primary
                loading={disabled}
                disabled={!hasChanges || !validState}
                onClick={onSubmit}>
                {newItem ? uiTexts.add : uiTexts.save}
              </Button>
            </SideBarFooter>
          </SideBar>
        );
      }}
    </ContactForm>

  );
};

export default React.memo(Sidebar);
