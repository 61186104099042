import { getTextsData } from "selectors/texts";
import { useSelector } from "react-redux";
import DataConstants from "const/DataConstants";
import React, { useMemo } from "react";
import Select from "nlib/ui/Select";

const { CONTACT_SUB_TYPES, CONTACT_SUB_TYPES: { VENDOR, CUSTOMER } } = DataConstants;

const SUB_TYPE_TO_TEXT_MAP = {
  [VENDOR]: "vendor",
  [CUSTOMER]: "customer"
};

const ContactFormSubTypeSelect = ({ ...restProps }) => {
  const { uiTexts } = useSelector(getTextsData);

  const options = useMemo(() => {
    return [
      ...Object.values(CONTACT_SUB_TYPES)
        .map((type) => ({
          value: type,
          label: uiTexts[SUB_TYPE_TO_TEXT_MAP[type]]
        })),
      { value: null, label: uiTexts.all }
    ];
  }, [uiTexts]);

  return (
    <Select
      placeholder={uiTexts.selectType}
      options={options}
      {...restProps} />
  );
};

export default React.memo(ContactFormSubTypeSelect);
